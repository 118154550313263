import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import TestContainer from 'containers/TestContainer';
import 'antd/dist/antd.css';

export default function App() {
	return (
		<IntlProvider locale='en' defaultLocale='en'>
			<BrowserRouter>
				<Routes>
					<Route path='/' element={<TestContainer />} />
				</Routes>
			</BrowserRouter>
		</IntlProvider>
	);
}
