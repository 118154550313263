import { Button, InputNumber, Table } from 'antd';
import { Fragment } from 'react';
export default function TestContainer() {
	const columns = [
		{ title: 'Item', dataIndex: 'item', key: 'item' },
		{ title: 'Props', dataIndex: 'props', key: 'props' },
		{ title: 'Quantity', dataIndex: 'quantity', key: 'quantity' },
		{
			title: 'Trade',
			dataIndex: 'actions',
			key: 'actions',
			render: (actions: any) => {
				return (
					<Fragment>
						<div>
							<span>{actions.bin}taco$</span>
							<Button>BIN</Button>
						</div>
						<div>
							<InputNumber placeholder='50' min={0} addonAfter='taco$' />
							<Button>offer</Button>
						</div>
					</Fragment>
				);
			},
		},
	];
	const data = [
		{ key: 1, item: 'Ist', props: 'weapon: +30mf, armor: +25mf, shield: +25mf', quantity: 1, actions: { bin: 60 } },
		{ key: 2, item: 'Ber', props: '+20% crushing blow', quantity: 1, actions: { bin: 1200 } },
	];
	return (
		<div>
			<div>
				<div>seller</div>
				<div>
					<>name:</>
					<>kamei</>
				</div>
				<div>
					<>rating:</>
					<>*****</>
				</div>
				<div>
					<>trades:</>
					<>1337</>
				</div>
			</div>
			<Table dataSource={data} columns={columns} pagination={false} />
		</div>
	);
}
